import React, {useContext, useEffect, useState} from "react"
import {LOG} from "../tools";
import { Container} from "@chakra-ui/react";
import {getDataPageLoad, getDataPageTag} from "../api/getData";
import TagContentHot from "../tag/components/TagContentHot";
import {GraphTimeLineMain} from "../graph/GraphTimeLineMain";
import PersonHeader from "../persons/components/PersonHeader";
import DataLoadContext from "../../context/DataLoadContext";
import TagContentVocabViz from "../tag/components/TagContentVocabViz";
import TagTimeline from "../tag/TagTimeline";
const PartiPage = ({tagJson})=>{
    const name = tagJson.n
    const id = tagJson.id
    const [dataHeader,setDataHeader] = useState({"lt":[],"nb":0})
        const [dataMainGraph,setDataMainGraph] = useState({"d": [],
    "sd": "2021-05-24 00:00:00"})
             const [dataTagVocabs,setDataTagVocabs] = useState(null)

    const {lastUpdate,updateLastUpdate} = useContext(DataLoadContext);
    useEffect(() => {
    getDataPageLoad("pageTag").then((d) =>{
        updateLastUpdate(d)
    });
  }, []);

    const nameFAnalyzer = "PageTags"
    const lastUpdatePageTag = lastUpdate.hasOwnProperty(nameFAnalyzer)?lastUpdate[nameFAnalyzer]:null
    useEffect(() => {
        LOG("getDataPageTag")
        if(lastUpdatePageTag!=null){
            getDataPageTag(id,lastUpdatePageTag).then((d) =>{
                LOG("getDataPageTag",d)
                if(d !=null){
                    setDataHeader(d["hc"])
                    setDataMainGraph(d["mg"])
                                        setDataTagVocabs(d["v"])
                }
            });
        }
  }, [lastUpdatePageTag]);


    return <Container maxW="container.xl" sx={{margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>

        <PersonHeader id={id} name={name} dataHeader={dataHeader}/>
                <TagContentVocabViz name={name} dataVocabs={dataTagVocabs}/>
        <TagTimeline tagId={id}/>
         <TagContentHot key={id}  personId={id}
          />

        <GraphTimeLineMain dataMainGraph = {dataMainGraph} stepWindow={60*24} stepWindowWord={60*24} />

         </Container>
}

export default PartiPage